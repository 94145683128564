import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Image = styled.img`
  /* position: absolute; */
  left: 0;
  top: 50%;
  transform: translate(0, 0);
  /* width: 100%; */
  /* height: 100%; */
  z-index: -1;
  min-height: 100%;
  width: 100%;
`;

export const Slide = styled.div`
  position: relative;
  max-height: 600px;
  box-shadow: 2px 0 16px rgba(0, 0, 0, 0.25);
  ${MEDIA.TABLET`
    max-height: 25vh;
  `};
  ${MEDIA.TABLET`
    max-height: 300px;
  `};
  .absolute-button {
    position: absolute;
    bottom: 4px;
    right: 20%;
    z-index: 9999;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
  }
`;

export const Action = styled.button`
  background-image: none !important;
  appearance: none;
  border: 0;
  background: rgba(0, 0, 0, 0);
`;
