import styled from 'styled-components';
import { mainColor } from 'constants/theme';

export const FormContainer = styled.div`
  width: 100%;
  label {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin: 8px 0;
    input {
      display: block;
      box-sizing: border-box;
      margin: 8px 0;
      padding: 8px;
      width: 100%;
      appearance: none;
      border: 1px solid rgba(0, 0, 0, 0.25);
    }
  }
  button {
    border: 0;
    background: ${mainColor};
    background: ${props => props.theme.primaryColor};
    color: #fff;
    padding: 8px 16px;
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: bold;
  }
`;
